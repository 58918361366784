






































import Component from "vue-class-component";
import { PageBase } from "@/core/models/shared";
import { EmpresaService, FornecedorService } from "@/core/services/geral";
import { Empresa } from "@/core/models/geral";
import { AlertSimpleErr } from "@/core/services/shared/AlertService";


@Component
export default class RelatorioFornecedorEquipamentoServico extends PageBase{
    service = new FornecedorService();
    itens: any [] = [];
    empresaService = new EmpresaService();
    empresa: Empresa = new Empresa();
    overlay: boolean = false;

    filtro: any = {
        fornecedorId: null,
        equipamentoServicoId: null,
        periodoId: null
    };

    mounted() {
        this.filtro.fornecedorId = this.$route.query.fornecedorId;
        this.filtro.equipamentoServicoId = this.$route.query.equipamentoServicoId;
        this.filtro.periodoId = this.$route.query.periodoId;
        this.Carregar();
    }

    Carregar(){
        this.overlay = true;

        this.empresaService.ObterPorId(this.app.empresaId, "Endereco.Municipio.Estado").then(
            res=>{
                this.empresa = res.data;
            },
            err=> AlertSimpleErr("Aviso!", err)
        )

        this.service.RelatorioServicoEquipamento(this.filtro.fornecedorId, this.filtro.equipamentoServicoId, this.filtro.periodoId).then(
            res => {
                this.itens = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.overlay = false;
        })
    }
}
